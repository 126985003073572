import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/(portal)": [~5,[2]],
		"/auth/error": [20],
		"/auth/success": [21],
		"/(portal)/compare-legacy": [7,[2]],
		"/(portal)/compare": [6,[2]],
		"/(portal)/contact": [8,[2]],
		"/(portal)/detail-legacy/[fund_id=sfid]/[[share_class_id=sfid]]/experience": [12,[4]],
		"/(portal)/detail-legacy/[fund_id=sfid]/[[share_class_id=sfid]]": [~11,[2]],
		"/(portal)/detail/[fund_id=sfid]/[[share_class_id=sfid]]/experience": [10,[3]],
		"/(portal)/detail/[fund_id=sfid]/[[share_class_id=sfid]]": [~9,[2]],
		"/(portal)/from-email/detail/[share_class_id=sfid]/[[access=login]]": [13,[2]],
		"/(portal)/from-email/document/[...file]": [14,[2]],
		"/(portal)/personal-data": [15,[2]],
		"/(portal)/portfolio": [16,[2]],
		"/(portal)/settings": [17,[2]],
		"/(portal)/terms-of-use": [18,[2]],
		"/[lang=lang]/[products=products]/[isin=isin]": [~19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';